import React, { useState, SyntheticEvent } from 'react'
import { useAuth } from '@contexts/AuthProvider'
import { Container } from '@components/Login'

const Login = () => {
  const { login, isLoading, error } = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [show, setShow] = useState(false)

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault()

    login({ email, password })
  }

  return (
    <Container
      show={show}
      setShow={setShow}
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
      handleSubmit={handleSubmit}
      error={error}
      loading={isLoading}
    />
  )
}

export default Login
